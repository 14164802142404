import { EventAggregator }             from 'aurelia-event-aggregator';
import { inject }                      from 'aurelia-framework';
import { I18N }                        from 'aurelia-i18n';
import { HighwaysRepository }          from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository } from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { OEDatasRepository }           from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { LotInterventionsRepository }  from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { PkTypesRepository }           from 'modules/management/concessions/concessions-tree/pk-types/services/repository';
import { EntitiesRepository }          from 'modules/management/concessions/entities/services/repository';
import { TeamsRepository }             from 'modules/management/concessions/teams/services/repository';
import { FieldsRepository }            from 'modules/management/dynamic-fields/association-sectors/services/fields-repository';
import { LotConstructionType }         from 'modules/management/models/lot-construction-type';
import { Sector }                      from 'modules/management/models/sector';
import { ConcessionsRepository }       from 'modules/management/specifications/concessions/services/repository';
import { ListingRevisionsRepository }  from 'modules/management/specifications/listings/listing-revisions/services/repository';
import { ListingsRepository }          from 'modules/management/specifications/listings/services/repository';
import { BaseFilterFormSchema }        from 'resources/classes/base-filter-form-schema';
import { SessionStorage }              from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ConcessionsRepository, EntitiesRepository, TeamsRepository, HighwaysRepository, InterventionTypesRepository, FieldsRepository, ListingsRepository, ListingRevisionsRepository, LotInterventionsRepository, OEDatasRepository, PkTypesRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        created_by:      [],
        created_at_from: null,
        created_at_to:   null,
    };

    constructor(storage, eventAggregator, i18n, concessionsRepository, entitiesRepository, teamsRepository, highwaysRepository, interventionTypesRepository, fieldsRepository, listingsRepository, listingRevisionsRepository, lotInterventionsRepository, oeDatasRepository, pkTypesRepository) {
        super(storage, eventAggregator, i18n);

        this.concessionsRepository       = concessionsRepository;
        this.entitiesRepository          = entitiesRepository;
        this.teamsRepository             = teamsRepository;
        this.highwaysRepository          = highwaysRepository;
        this.interventionTypesRepository = interventionTypesRepository;
        this.fieldsRepository            = fieldsRepository;
        this.listingsRepository          = listingsRepository;
        this.listingRevisionsRepository  = listingRevisionsRepository;
        this.lotInterventionsRepository  = lotInterventionsRepository;
        this.oeDatasRepository           = oeDatasRepository;
        this.pkTypesRepository           = pkTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel, selectedLotInformation) {
        this.modelDefaults = {
            ...this.modelDefaults,
            entity_name: selectedLotInformation.entity_name,
            entity_id:   selectedLotInformation.entity_id,
            team_name:   selectedLotInformation.team_name,
            team_id:     selectedLotInformation.team_id,
        };

        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param isBuilder
     * @returns {*[]}
     */
    async schema(viewModel, isBuilder = false) {

        const LAB_WORK_CONTROL = Sector.LAB_WORK_CONTROL;
        const LAB_USE_IN       = Sector.LAB_USE_IN;

        const branchLabel = 'form.field.branch';
        const edgeLabel   = 'form.field.edge';

        const filterData = async (newValue) => {
            return await this.oeDatasRepository.filterData(newValue);
        }

        const filterEdgeBranchPK = async (newValue) => {

            let branch = this.branch_id;
            let edge   = this.edge_id;

            edge.instance.hide(false);
            branch.instance.hide(false);
            edge.instance.clear(!newValue);
            branch.instance.clear(!newValue);

            branch.instance.disable(!newValue);
            edge.instance.disable(!newValue);
            await updateOELotConstructionData();

            /* Get filtered data from backend */
            const { predominant, branch_options, edge_options, pk_options } = await filterData(newValue);

            edge.label   = edgeLabel;
            branch.label = branchLabel;

            switch (predominant) {
                case 'branch_id':
                    edge.instance.hide();

                    branch.options = branch_options;

                    edge.label = 'form.text.not-applicable';
                    break;

                case 'edge_id':
                    branch.instance.hide();

                    edge.options = edge_options;

                    branch.label = 'form.text.not-applicable';
                    break;

                case 'pk_id':
                    edge.instance.hide();
                    branch.instance.hide();

                    branch.label = 'form.text.not-applicable';
                    edge.label   = 'form.text.not-applicable';
                    break;
            }

            branch.instance.disable(predominant !== 'branch_id')
            edge.instance.disable(predominant !== 'edge_id')
        }

        const updateOELotConstructionData = async (source = null) => {
            // Early bird gets the worm.
            if (!source) {
                viewModel.filterModel.o_e_lot_construction_data_id = null;
                viewModel.filterModel.number_of_tracks             = null;
                viewModel.filterModel.axis                         = null;
                return;
            }

            let selectedRecord = source.instance.selectedRecord();
            let oeDataId;

            if (source === this.OE_LOT_CONSTRUCTION_DATA_ID) {
                oeDataId = selectedRecord.id
            } else {
                oeDataId = selectedRecord.o_e_lot_construction_data_id;
            }

            let oeData = await this.oeDatasRepository.find(oeDataId);

            viewModel.filterModel.o_e_lot_construction_data_id = oeDataId;
            viewModel.filterModel.number_of_tracks             = oeData.number_of_lanes;
            viewModel.filterModel.axis                         = oeData.axis;
        }

        this.test_number_from = {
            type:     'number',
            key:      'test_number_from',
            label:    'form.field.sample-number(from)',
            size:     3,
            required: false,
        };

        this.test_number_to = {
            type:     'number',
            key:      'test_number_to',
            label:    'form.field.sample-number(to)',
            size:     3,
            required: false,
        };

        this.concession_id = {
            type:         'select2',
            key:          'concession_id',
            label:        'form.field.concession',
            size:         6,
            remoteSource: () => this.concessionsRepository.all(),
            required:     false,
            observers:    [
                (newValue) => {
                    this.highway_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.concessionsRepository.highways(newValue).then((response) => {
                            this.highway_id.options = response;
                            this.highway_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        /* Selectable IDs if is not builder */
        this.entity_id = {
            type:         'select2',
            key:          'entity_id',
            label:        'form.field.entity',
            size:         6,
            remoteSource: () => this.entitiesRepository.all(),
            required:     false,
            hidden:       isBuilder,
            attributes:   {
                disabled: false,
            },
            observers:    [
                (newValue) => {
                    this.team_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.entitiesRepository.teams(newValue).then((response) => {
                            this.team_id.options = response;
                            this.team_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.team_id = {
            type:         'select2',
            key:          'team_id',
            label:        'form.field.team',
            size:         6,
            remoteSource: () => (viewModel.filterModel.entity_id)
                ? this.entitiesRepository.teams(viewModel.filterModel.entity_id)
                : Promise.resolve([]),
            required:     false,
            hidden:       isBuilder,
            attributes:   {
                disabled: false,
            },
        };

        /* Readonly names if is builder */
        this.entity_name = {
            type:       'text',
            key:        'entity_name',
            label:      'form.field.entity',
            size:       6,
            required:   false,
            hidden:     !isBuilder,
            attributes: {
                readonly: true,
            },
        };

        this.team_name = {
            type:       'text',
            key:        'team_name',
            label:      'form.field.team',
            size:       6,
            required:   false,
            hidden:     !isBuilder,
            attributes: {
                readonly: true,
            },
        };

        /* Rest of the fields */
        this.highway_id = {
            type:         'select2',
            key:          'highway_id',
            label:        'form.field.highway',
            size:         6,
            remoteSource: () => Promise.resolve([]),
            required:     false,
            attributes:   {
                disabled: true,
            },
            observers:    [
                (newValue) => {
                    this.intervention_type_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.highwaysRepository.interventionTypes(newValue).then((response) => {
                            this.intervention_type_id.options = response;
                            this.intervention_type_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.intervention_type_id = {
            type:         'select2',
            key:          'intervention_type_id',
            label:        'form.field.intervention-type',
            size:         6,
            remoteSource: () => Promise.resolve([]),
            required:     false,
            attributes:   {
                disabled: true,
            },
            observers:    [
                (newValue) => {
                    this.lot_intervention_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.interventionTypesRepository.lotInterventions(newValue).then((response) => {
                            this.lot_intervention_id.options = response;
                            this.lot_intervention_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.lot_intervention_id = {
            type:         'select2',
            key:          'lot_intervention_id',
            label:        'form.field.lot-intervention',
            size:         6,
            remoteSource: () => Promise.resolve([]),
            observers:    [
                (newValue) => {
                    this.lot_construction_id.instance.disable(!newValue);
                    this.listing_revision_id.instance.disable(!newValue);

                    // Based on new value for lot intervention
                    if (newValue) {
                        // Refresh filter for lot constructions
                        this.lotInterventionsRepository.allConstructions(newValue, LotConstructionType.OE).then((response) => {
                            this.lot_construction_id.options = response;
                            this.lot_construction_id.instance.enable(response.length);
                        });
                        // Refresh filter for listing revisions
                        this.listingsRepository.allFromSector(viewModel.sectorId, {
                            lot_intervention_id: newValue,
                        }).then((response) => {
                            this.listing_revision_id.options = response;
                            this.listing_revision_id.instance.enable(response.length);
                        });
                    }
                },
            ],
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.external_reference = {
            type:     'text',
            key:      'external_reference',
            label:    'form.field.external-reference',
            size:     6,
            required: false,
        };

        this.sample_collection_id = {
            type:         'multiselect-native',
            key:          'sample_collection_id',
            label:        'form.field.sample-collection',
            remoteSource: () => this.fieldsRepository.getFields(viewModel.sectorId,
                'sample-collection',
                { slug: [LAB_WORK_CONTROL, LAB_USE_IN] },
            ),
            size:         6,
            required:     false,
        };

        this.date_from = {
            type:     'material-ui-date-picker',
            key:      'date_from',
            label:    'form.field.collection-date(from)',
            size:     3,
            required: false,
        };

        this.date_to = {
            type:     'material-ui-date-picker',
            key:      'date_to',
            label:    'form.field.collection-date(to)',
            size:     3,
            required: false,
        };

        this.listing_revision_id = {
            type:         'select2',
            key:          'listing_revision_id',
            label:        'form.field.listings',
            size:         6,
            remoteSource: () => this.listingsRepository.allFromSector(viewModel.sectorId, {
                lot_intervention_id: viewModel.lot_intervention_id,
            }),
            required:     false,
            observers:    [
                (newValue) => {
                    this.bulletin_id.instance.disable(!newValue)

                    if (newValue) {
                        this.listingRevisionsRepository.getBulletins(newValue).then((response) => {
                            this.bulletin_id.options = response;
                            this.bulletin_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.bulletin_id = {
            type:         'select2',
            key:          'bulletin_id',
            label:        'form.field.tests',
            size:         6,
            remoteSource: () => Promise.resolve([]),
            required:     false,
        };

        this.lot_construction_id = {
            type:         'select2',
            key:          'lot_construction_id',
            label:        'form.field.local',
            remoteSource: () => viewModel.lot_intervention_id
                ? this.lotInterventionsRepository.allConstructions(viewModel.lot_intervention_id, LotConstructionType.OE)
                : Promise.resolve([]),
            size:         4,
            observers:    [
                (newValue) => {
                    if (newValue) {
                        filterEdgeBranchPK(newValue);
                    }
                },
            ],
        };

        this.branch_id = {
            type:         'select2',
            key:          'branch_id',
            label:        branchLabel,
            remoteSource: () => Promise.resolve([]),
            size:         4,
            attributes:   {
                disabled: true,
            },
            observers:    [
                (newValue) => {
                    if (newValue)
                        updateOELotConstructionData(this.branch_id);
                },
            ],
        };

        this.edge_id = {
            type:         'select2',
            key:          'edge_id',
            label:        'form.field.edge',
            remoteSource: () => Promise.resolve([]),
            size:         4,
            attributes:   {
                disabled: true,
            },
            observers:    [
                (newValue) => {
                    if (newValue)
                        updateOELotConstructionData(this.edge_id);
                },
            ],
        };

        this.collection_pk = {
            type:     'field-group',
            label:    'form.field.collection-pk',
            key:      'collection_pk',
            required: false,
            size:     8,
            fields:   [
                {
                    type:       'divider',
                    label:      viewModel.appContainer.i18n.tr('form.field.from'),
                    size:       '30p',
                    attributes: {
                        style: 'font-size: .9rem; margin: 0 .5rem 0 .75rem; display: inline-block;',
                    },
                },
                {
                    type:            'text',
                    key:             'pk_s_from',
                    showLabel:       false,
                    required:        false,
                    size:            '130p',
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  '30p',
                },
                {
                    type:            'text',
                    key:             'pk_f_from',
                    showLabel:       false,
                    required:        false,
                    size:            '130p',
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                    },
                },
                {
                    type:       'divider',
                    label:      viewModel.appContainer.i18n.tr('form.field.to'),
                    size:       '30p',
                    attributes: {
                        style: 'font-size: .9rem; margin: 0 .5rem 0 1.5rem; display: inline-block;',
                    },
                },
                {
                    type:            'text',
                    key:             'pk_s_to',
                    showLabel:       false,
                    required:        false,
                    size:            '130p',
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  '30p',
                },
                {
                    type:            'text',
                    key:             'pk_f_to',
                    showLabel:       false,
                    required:        false,
                    size:            '130p',
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                    },
                },
            ],
        }

        this.pk_type_id = {
            type:         'select2',
            idPrefix:     this.schemaSlug,
            key:          'pk_type_id',
            label:        'form.field.pk-type',
            size:         4,
            remoteSource: () => this.pkTypesRepository.all(),
            observers:    [],
            required: false,
        };


        this.validation_status = {
            type:     'boolean-statuses-select',
            key:      'validation_status',
            label:    'form.field.validation-status',
            size:     4,
            required: false,
        };

        this.inspection_status = {
            type:     'boolean-statuses-select',
            key:      'inspection_status',
            label:    'form.field.inspection-status',
            size:     4,
            required: false,
        };

        this.concessionary_status = {
            type:     'boolean-statuses-select',
            key:      'concessionary_status',
            label:    'form.field.concessionary-status',
            size:     4,
            required: false,
        };

        this.created_by = {
            type:     'users-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     4,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     4,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     4,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', {
                listingId:      viewModel.listingId,
                ignoredFilters: isBuilder ? ['entity_id', 'entity_name', 'team_id', 'team_name'] : [],
            }),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.test_number_from, this.test_number_to, this.concession_id],
            [this.entity_id, this.team_id],
            [this.entity_name, this.team_name],
            [this.highway_id, this.intervention_type_id],
            [this.lot_intervention_id, this.external_reference],
            [this.sample_collection_id, this.date_from, this.date_to],
            [this.listing_revision_id, this.bulletin_id],
            [this.lot_construction_id, this.branch_id, this.edge_id],
            [this.collection_pk, this.pk_type_id],
            [this.validation_status, this.inspection_status, this.concessionary_status],
            [this.created_by, this.created_at_from, this.created_at_to],
            [this.buttons],
        ];
    }
}
